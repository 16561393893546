import React, {Component, Fragment} from 'react';
import {graphql, Link, navigate} from 'gatsby';
import Layout from '../components/layout';
import PromoFooter from '../components/promo-footer-banner';
import MarketoForm from '../components/marketo';
import {mapCoolInsights, mapStickyBanner, mapGartner, videoSectionMapper, mapResourcesList} from '../common/utils';
import CatoImg from "../components/cato-image";
import CustomerSideQuote from '../components/customer-side-quote';
import {IS_MOBILE, RESOURCE_FORM_IDS, LANG_PATH_PREFIX} from "../common/consts";
import ResourceDownload from '../components/get-resource';
import VideoWithLightbox from '../components/video-lightbox';
import VideoLightboxMapper from '../components/video-lightbox/mapper';
import CatoYoutube from '../components/cato-youtube';
import '../assets/styles/pages/landing-page.scss';
import '../assets/styles/responsive/pages/landing-page-mobile.scss';

class LandingPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false
        };

        if(typeof window !== 'undefined') {
            //kept to clear up earlier localStorage saving
            window.localStorage.removeItem('Ty_FileUrl');
            window.localStorage.removeItem('Ty_YTUrl');
        }

        this.lang = props.pageContext.lang;
    }

    componentDidMount() {
        if(IS_MOBILE) {
            this.setState({isMobile: true});
        }


    }

    generateQuotes = (acf) => {

        const quoteList = acf.landingQuotes.map(quote => ({
            quote: quote.quote,
            logoWithName: true,
            name: quote.quoteName,
            position: quote.sourcePosition,
            companyLogo: quote.companyLogo ? quote.companyLogo :  null,
            customerImg: quote.quoteImage ? quote.quoteImage: null,
            className: 'wide'
        }))

        return (
            quoteList.map((quote, i) => <CustomerSideQuote {...quote} key={`quote-${i}`}/>)
        )
    };

    generateSpeakers = (landingPage) => {
        return ( <div className={`speakers${landingPage.speakers.length === 1 ? ' one' : ''}`}>
            {landingPage.speakers.map((item, i) => (
                <div className="speaker">
                    <div className="img-and-name">
                        {item.landingSpeakerImage &&
                            <CatoImg img={item.landingSpeakerImage}/>
                        }
                        {item.landingSpeakerName && <span>{item.landingSpeakerName}</span>}
                    </div>
                    {item.landingSpeakerDescription && <p>{item.landingSpeakerDescription}</p>}
                </div>
            ) )}
        </div>)
    };

    getAssetValue = () => {
        const {landingPage} = this.props.data.wpPage;
        const {state} = this.props.location;

        const video = landingPage.thankYouPageYoutubeVideoId;
        const file = landingPage.thankYouPageDownloadFileUrl;

        if(video || file) {
            if(video) {
                return [video, 'video'];
            } else {
                return [file, 'download'];
            }
        } else if (state) {
            return [state.assetValue, state.assetType];
        }

        return [];
    };

    getRedirect = () => {
        const {resourceNextPage} = this.props.data.wordpressPage.landingPage;

        if(resourceNextPage && resourceNextPage.length) {
            if(resourceNextPage[0].status !== 'publish') {
                return `/${resourceNextPage[0].databaseId}/`
            } else {
                return `/resources/${resourceNextPage[0].title}/`
            }
        }
        return '/access-your-content/';
    };

    onFormSuccess = () => {
        const [assetValue, assetType] = this.getAssetValue();
    const {landingPage} = this.props.data.wpPage;

        const redirect = landingPage.resourceNextPage ? `${LANG_PATH_PREFIX[this.lang]}resources/${landingPage.resourceNextPage[0].path}/` : `${LANG_PATH_PREFIX[this.lang]}access-your-content/`;

        navigate(
            redirect,
            {
               state: {
                   assetValue,
                   assetType
               },
               replace: false
            }
        );

    };

    render() {
        const {isMobile} = this.state;
        const {data} = this.props;
        const {wpPage} = data;
        const {landingPage, resourcesBannerRows, videoSection, videoLightboxParent} = wpPage;
        const speakers = landingPage.landingShowSpeaker && !landingPage.landingShowQuote ? this.generateSpeakers(landingPage) : null;
        const quotes = landingPage.landingShowQuote && !landingPage.landingShowSpeaker ? this.generateQuotes(landingPage) : null;
        const resourcesData = resourcesBannerRows && resourcesBannerRows.resourcesList ? mapResourcesList(resourcesBannerRows.resourcesList) : null;
        const youtube = videoSection && videoSection.videos ? videoSectionMapper(videoSection.videoSectionHeadline, videoSection.videos) : null;
        const resource = !landingPage.landingForm ?{
            link: landingPage.thankYouPageDownloadFileUrl || landingPage.thankYouPageYoutubeVideoId,
            type: landingPage.thankYouPageDownloadFileUrl ? 'download' : 'video',
            buttonPosition: 'below',
            buttonLabel: landingPage.resourceButtonLabel || 'DOWNLOAD'
        } : null;

        const coolInsights = mapCoolInsights(wpPage);

        const assetImage = (landingPage.landingShowAsset) ? typeof window !== 'undefined' &&
            document.documentElement.clientWidth < 1500 ? landingPage.mobileAsset : landingPage.landingAssetImage : null;

        const stickyBanner = mapStickyBanner(wpPage);

        const showYouTube = (this.props.location.state && this.props.location.state.assetType === 'video');

        const gartner = mapGartner(wpPage);

        const videoLightbox = videoLightboxParent.videoLightbooxCode ? VideoLightboxMapper(videoLightboxParent) : null;

        const formLanguage = landingPage.landingLanguage ? landingPage.landingLanguage : this.lang;

        return (
            <Layout className="landing-page"
                    breadcrumbs={[{title: 'Cato Library', path: '/resources/'}, {title: wpPage.title, path: this.props.location.pathname}]}
                    lang={this.lang}
                    whiteMenu={true}
                    yoastData={{...wpPage.seo, canonical: null} || null}
                    seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
                    title={wpPage.title}
                    gartner={gartner}
                    seoPath={this.props.location.pathname}
                    translations={this.props.pageContext.translations}
                    wpid={wpPage.databaseId}

                    {...{stickyBanner}}
                    {...{youtube}}
                    {...{coolInsights}}
            >
                <section className={`top${landingPage.landingShowRing ? ' with-ring': ''}${landingPage.landingShowAsset ? ' with-asset': ''}`}>
                    {isMobile &&
                    <Fragment>
                        {landingPage.landingResourceType && <span className="resource-type">{landingPage.landingResourceType}</span>}
                        {landingPage.landingHeadline && <div className="heading" style={{margin: '0'}} dangerouslySetInnerHTML={{__html: landingPage.landingHeadline}}/>}
                        {videoLightbox &&
                            <VideoWithLightbox
                                items={[videoLightbox]}
                            />
                        }
                        {showYouTube && <CatoYoutube videoId={this.props.location.state.asset} style={{maxWidth: '100%'}}/>}
                    </Fragment>
                    }
                    <div className="content-wrapper">
                        {!isMobile &&
                            <div className="bread-crumbs">
                                <Link to="/">Home</Link>
                                <span className="arrow"> > </span>
                                <span>Resources</span>
                            </div>
                        }
                        <div className="top-text">
                            {!isMobile &&
                            <Fragment>
                                {landingPage.landingResourceType && <span className="resource-type">{landingPage.landingResourceType}</span>}
                                {landingPage.landingHeadline &&
                                <div style={{margin: '0'}} className="heading" dangerouslySetInnerHTML={{__html: landingPage.landingHeadline}}/>
                                }
                                {   videoLightbox &&
                                    <VideoWithLightbox
                                        items={[videoLightbox]}
                                    />
                                }
                                {showYouTube && <CatoYoutube videoId={this.props.location.state.assetValue}/>}
                            </Fragment>
                            }
                            {landingPage.landingDescription &&
                            <div className="content" dangerouslySetInnerHTML={{__html: landingPage.landingDescription}}/>}
                        </div>
                        {(speakers || quotes) &&
                        <div className={`extras${quotes ? ' with-quote' : ''}`}>
                            {speakers}
                            {quotes}
                        </div>
                        }
                    </div>
                    <div className={`form-wrapper${landingPage.landingShowRing ? ' with-ring': ''}${!landingPage.landingForm ? ' no-form' : ''}`}>
                        {landingPage.LandingFormHeadline && <h3 className={`${!landingPage.landingForm ? 'center' : ''}`}>{landingPage.landingFormHeadline}</h3>}
                        {landingPage.landingForm &&
                            <MarketoForm
                                formId={RESOURCE_FORM_IDS[formLanguage][landingPage.landingForm]}
                                event={{label: this.props.location.pathname}}
                                onFormSuccess={this.onFormSuccess}
                            />
                        }
                        {
                            resource &&
                                <ResourceDownload
                                    {...resource}
                                />
                        }
                        {!isMobile && assetImage &&
                            <div className="side-asset">
                                <CatoImg img={assetImage}/>
                            </div>
                        }
                    </div>
                </section>
                {resourcesData &&
                    <div>
                        <PromoFooter data={resourcesData}/>
                    </div>
                }
            </Layout>
        )
    }
}


export default LandingPage;

export const query = graphql`
    query landingPageQuery($id: String!) {
        wpPage(id: {eq: $id}) {
            ...YoastData
            title
            content
            slug
            id
            date(formatString: "MMMM DD, YYYY")
            path: uri
            databaseId
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            }
            landingPage {
                landingForm
                landingFormHeadline
                landingLanguage
                landingResourceType
                landingShowRing
                landingHeadline
                landingDescription
                landingShowAsset
                thankYouPageDownloadFileUrl
                thankYouPageYoutubeVideoId
                resourceButtonLabel
                landingAssetImage{
                    altText
                    localFile{
                        childImageSharp {
                            fixed(height: 248){
                                ...GatsbyImageSharpFixed_withWebp_noBase64
                            }
                        }
                        publicURL
                    }
                }
                mobileAsset: landingAssetImage{
                    altText
                    localFile{
                        childImageSharp {
                            fixed(height: 180){
                                ...GatsbyImageSharpFixed_withWebp_noBase64
                            }
                        }
                        publicURL
                    }
                }
                landingShowSpeaker
                speakers {
                    landingSpeakerImage {
                        altText
                        localFile {
                            childImageSharp {
                                fixed(width: 75, height: 75){
                                    ...GatsbyImageSharpFixed_withWebp_noBase64
                                }
                            }
                            publicURL
                        }
                    }
                    landingSpeakerName
                    landingSpeakerDescription
                }
                landingShowQuote
                landingQuotes {
                    quote
                    quoteImage {
                        altText
                        localFile {
                            childImageSharp {
                                fixed(width: 50, height: 50){
                                    ...GatsbyImageSharpFixed_withWebp_noBase64
                                }
                            }
                            publicURL
                        }
                    }
                    quoteName
                    sourcePosition
                    companyLogo {
                        altText
                        localFile {
                            childImageSharp {
                                fixed(height: 27){
                                    ...GatsbyImageSharpFixed_withWebp_noBase64
                                }
                            }
                            publicURL
                        }
                    }
                }
               
                resourceNextPage {
                    ... on WpPage {
                        id
                        path: uri
                        status
                        databaseId
                        title
                    }
                }
            }
                
            ...VideoLightbox
            ...StickyBanner
            ...VideoSection
            ...ResourcesTrio
            ...GartnerStrip
            ...ResourcesBannerRows
        }
    }
`;