import React from 'react';
import catoEbook from '../../assets/images/cato-ebook.png';
import CatoYoutube from '../cato-youtube';
import './style.scss';


const ResourceDownload = ({link, type, buttonLabel, buttonPosition}) => {

    const Download = type === 'download' ?
        <>
        {buttonPosition === 'above' &&
            <a className={'cato-button resources-green-button download-link'} href={link} target={'_blank'}>{buttonLabel}</a>
        }
        <img className="download-link-img" src={catoEbook} alt={'ebook'} style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}}/>
        {buttonPosition === 'below' &&
        <a className={'cato-button resources-green-button download-link'} href={link} target={'_blank'}>{buttonLabel}</a>
        }
        </>
        :
        null;

    const YouTube = type === 'video' ?
        <iframe title="yt vid" width="560" height="315" src={`https://www.youtube.com/embed/${link}`} frameBorder="0"
                                                 allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                 allowFullScreen/>
        : null;

    return Download || YouTube || null;
};

ResourceDownload.defaultProps = {
    buttonLabel: 'DOWNLOAD',
    buttonPosition: 'above'
}


export default ResourceDownload;